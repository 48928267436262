@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

//Font
$openSansFont: 'Open Sans', sans-serif;

//Color
$color-grey: #64666b;
$color-grey-accent: #efefef;
$color-White: #fff;
$color-blue:#0058D0;
