@import '../../../../assets/scss/variable.scss';

// .dialog-title {
//   font-family: $openSansFont !important;
//   font-size: 42px !important;
//   line-height: 57px !important;
//   font-weight: 700 !important;
//   color: $color-grey !important;
// }
.dialog-para {
  font-family: $openSansFont !important;
  font-size: 22px !important;
  line-height: 30px !important;
  font-weight: 400 !important;
  color: $color-grey !important;
}
.pb-30 {
  padding-bottom: 30px;
}
