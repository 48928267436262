@import '../scss//variable.scss';

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  color: $color-grey;
  font-family: $openSansFont;

  .container {
    max-width: 950px;
    margin: 0 auto;
    width: 100%;
    padding: 0 14px;
  }

  .bg-color {
    background-color: $color-grey;
  }

  .open-font {
    font-family: $openSansFont;
  }

  .mb-11 {
    margin-bottom: 11px;
  }

  .pb-22 {
    padding-bottom: 22px;
  }

  .pb-35 {
    padding-bottom: 35px;
  }

  .pb-46 {
    padding-bottom: 46px;
  }

  .text-danger {
    color: red;
  }

  .custom-loader {
    text-align: center;

    .chakra-spinner {
      height: 50px;
      width: 50px;
    }
  }

  // BUTTONS BACKGROUND
  .login-sec {
    .MuiButton-contained:hover {
      background-color: $color-White !important;
      box-shadow: none;
    }
  }

  .btn-White {
    color: $color-grey;
    text-transform: uppercase;
    background: $color-White;
    font-family: $openSansFont;
    padding: 12px 71px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    border-radius: 0;
    height: 62px;

    @media (max-width: 479px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (max-width: 359px) {
      padding: 12px 36px;
    }
  }

  .pb-70 {
    padding-bottom: 70px;
  }

  .pb-11 {
    padding-bottom: 11px;
  }

  .regular-font {
    font-size: 16px;
    color: $color-grey;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 11px;
    font-family: $openSansFont;
  }

  .bold-font {
    font-size: 22px;
    color: $color-grey;
    line-height: 30px;
    font-weight: 700;
    font-family: $openSansFont;
  }

  .divider {
    // background: $color-grey-accent;
    border: 1px solid $color-grey-accent;
  }

  // ::placeholder {
  //   color: red;
  //   opacity: 1; /* Firefox */
  // }
  .registerd-divider {
    margin: 40px 0 33px 0;
  }

  .continue-btn {
    width: 100%;
    background-color: $color-grey;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: $color-White;
    height: 62px;
    border-radius: 0;

    @media screen and (min-width: 320px) and (max-width: 768px) {
      width: 100%;
      min-width: 0px;
      margin-bottom: 19px;
    }

    .MuiButton-root {
      padding: 0;
    }
  }

  .MuiButton-contained:hover {
    background-color: $color-grey;
  }
}

// TOSTIFY
.Toastify__toast-container {
  .Toastify__toast-theme--colored.Toastify__toast--info {
    background-color: #438ac1;
    color: #fff;
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #438ac1;
    color: #fff;
  }

  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: #438ac1;
    color: #fff;
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: red;
  }

  .Toastify__close-button {
    opacity: 0.8;
    color: #fff;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.MuiButton-root:hover {
  background-color: $color-grey !important;
}

.btn-disable {
  .Mui-disabled {
    background-color: #C6C6C6 !important;
  }
}