@import "../../../../assets/scss/variable.scss";

.accept-frontend-conditions {
  .company-name-field {
    margin-bottom: 28px;
  }
  .contact-person-field {
    margin-bottom: 37px;
  }
  .email-address-field {
    margin-bottom: 48px;
  }
  .required_text {
    font-family: $openSansFont;
    font-size: 12px;
    line-height: 17px;
    color: $color-grey;
  }
  .underline {
    color: $color-grey;
    text-decoration: underline;
  }
  .required-check-box {
    margin-top: 31px;
    .MuiIconButton-root {
      padding: 0px 12px 0px 12px;
    }
    .pl-15 {
      padding-left: 15px;
    }
  }
  .continue-sec {
    margin: 48px 0px 66px;
    .continue-btn {
      width: 100%;
      background-color: $color-grey;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      color: $color-White;
      padding: 18px 0px 13px 0px;
      :hover {
        background-color: $color-grey;
      }
      @media screen and (min-width: 320px) and (max-width: 768px) {
        width: 100%;
        min-width: 0px;
        margin-bottom: 19px;
      }
    }
  }
}
