@import "../../../assets/scss/variable.scss";

.custom-dialog {
  ::-webkit-scrollbar {
    width: 0;
  }
  .MuiDialog-paper {
    box-shadow: none;
    // border-radius: $bRadius;
    max-width: 862px;
    height: 635px;
    width: 100%;
    padding: 36px 36px 36px 36px;
    margin: auto 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: $blockColor;
    // border: 1px solid #707070;
    @media (max-width: 599px) {
      padding: 15px 12px;
    }
    // @media (max-width: $mobileBreakPoint) {
    //   margin: 0;
    //   // position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   min-height: 100vh;
    //   min-width: 100%;
    //   padding-top: 30px;
    //   overflow-x: hidden;
    //   padding-bottom: 0;
    // }
    .MuiDialogTitle-root {
      padding: 0;
    }
    .dialog-content-box {
      .dialog-close {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .custom-dialog-title {
        position: relative;
        .dialog-title {
          width: 100%;
          font-size: 42px;
          line-height: 57px;
          // color: $color-white;
          // font-family: $authHeaderFont;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 40px;
          font-weight: 700;
          color: $color-grey;
          font-family: $openSansFont;

          @media (max-width: 599px) {
            font-size: 26px;
            margin-top: 19.5px;
          }

          svg {
            @media (max-width: 599px) {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
    // @media (max-width:480px){
    //   margin: 0%;
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   min-height: calc(100% + 16px);
    //   width: 100%;
    //   padding-top: 0;
    //   overflow-x: hidden;
    //   .dialog-content-box {
    //     position:sticky;
    //     top:0;
    //     padding-top:10px;
    //     z-index: 2;
    //     background-color: #171717;
    //     width: 100%;
    //     margin-bottom:10px;
    //   }
    //   .dialog-submit-container {
    //     position: sticky;
    //     bottom: 0;
    //     background-color: #171717;
    //     padding-top: 0;
    //     margin-top: 20px;
    //     z-index: 2;
    //   }
    //   .dialog-submit-button {
    //     padding-bottom:25px;
    //     margin-top: 0;
    //   }
    // }
  }
}

.dialog-submit-button {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 32px 36px 0 36px;
  border-top: 1px solid #696969;
  margin: 0px -36px;
  margin-top: 12px;
  @media (max-width: 599px) {
    padding: 32px 36px 0 36px;
  }

  .dialog-submit {
    // border-radius: $bRadius;
    width: 100%;
    font-size: 21.33px;
    line-height: 26px;
    // font-family: $authHeaderFont;
    // color: $color-black-very-light;
    // background-color: $color-white;
  }
}
.mr-5 {
  margin-right: 5px;
}
.autocomplete-dropdown-container {
  .MuiMenuItem-root {
    white-space: unset;
  }
}
.claim-profile-dialog {
  // @media (max-width: $mobileBreakPoint) {
  //   .MuiDialog-paper {
  //     margin: 0;
  //     min-width: 100%;
  //     min-height: 100%;
  //     .custom-dialog-title {
  //       .dialog-title {
  //         text-align: start;
  //       }
  //     }
  //   }
  //   .dialog-submit-button {
  //     position: fixed;
  //     bottom: 0;
  //     width: 100%;
  //     margin-left: -12px;
  //     background-color: #171717;
  //     padding: 16px 36px;
  //   }
  // }
}

.mobile-drawer {
  .custom-mobile-drawer-title {
    position: relative;
    margin-top: 10px;
    .mobile-drawer-title {
      width: 100%;
      margin-top: 3px;
      font-size: 25px;
      line-height: 30px;
      // color: $color-white;
      // font-family: $primaryFont;
      font-weight: bold;
      margin-bottom: 19px;
    }
  }
}
.banner-sec {
  .logo {
    padding-bottom: 59px;
  }
  background-color: $color-grey;
  text-align: center;
  padding: 60px 0px 59px 0px;
  .title-text {
    font-family: $openSansFont;
    font-size: 40px;
    line-height: 55px;
    font-weight: 700;
    color: $color-White;
    @media (max-width: 425px) {
      font-size: 30px;
      line-height: 44px;
    }
  }
  .para-text {
    font-family: $openSansFont;
    font-size: 25px;
    line-height: 34px;
    font-weight: 400;
    color: $color-White;
    @media (max-width: 425px) {
      font-size: 24px;
      line-height: 31px;
    }
  }
}
.description-sec {
  padding: 59px 0px 0px 0px;
  .desc-title-text {
    font-family: $openSansFont;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    color: $color-grey;
  }
  .desc-para-text {
    font-family: $openSansFont;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: $color-grey;
    @media (max-width: 425px) {
      font-size: 15px;
      line-height: 20px;
    }
  }
}

.online-procurement-sec {
  border: 3px solid #a70000;
  padding: 43px 0px 42px 0px;
  margin: 36px 0px 74px 0px;
  .online-procurement-text {
    font-family: $openSansFont;
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    color: $color-grey;
  }
}

.current-person-sec {
  margin: 41px 0px 0px 0px;
  .current-title-text {
    font-family: $openSansFont;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    color: $color-grey;
  }
  .money-text {
    font-family: $openSansFont;
    font-size: 52px;
    line-height: 80px;
    font-weight: 700;
    color: $color-grey;
    @media (max-width: 320px) {
      font-size: 45px;
      line-height: 45px;
    }
  }
}

.continue-sec {
  margin: 48px 0px 66px;
  .continue-btn {
    width: 100%;
    background-color: $color-grey;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: $color-White;
    padding: 18px 0px 13px 0px;
    @media screen and (min-width: 320px) and (max-width: 768px) {
      width: 100%;
      min-width: 0px;
      margin-bottom: 19px;
    }
  }
}
