.pageNotMain {
  ul {
    list-style-type: none;
    padding-inline-start: 35px;
  }

  .page-not {
    height: 500px;
    width: 500px;
  }

  svg {
    width: 100%;
    visibility: hidden;
  }

  .d-flex {
    display: flex;
    align-items: center;
  }

  h1 {
    font-size: 7.5em;
    margin: 15px 0px;
    font-weight: bold;
  }

  h2 {
    font-weight: bold;
  }

  .hamburger-menu {
    position: absolute;
    top: 0;
    left: 0;
    padding: 35px;
    z-index: 2;

    & button {
      position: relative;
      width: 30px;
      height: 22px;
      border: none;
      background: none;
      padding: 0;
      cursor: pointer;

      & span {
        position: absolute;
        height: 3px;
        background: #000;
        width: 100%;
        left: 0px;
        top: 0px;
        transition: 0.1s ease-in;

        &:nth-child(2) {
          top: 9px;
        }

        &:nth-child(3) {
          top: 18px;
        }
      }
    }

    & [data-state='open'] {
      & span {
        &:first-child {
          transform: rotate(45deg);
          top: 10px;
        }

        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 10px;
        }
      }
    }
  }

  nav {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--green);
    color: var(--blue);
    width: 300px;
    z-index: 1;
    padding-top: 80px;
    transform: translateX(-100%);
    transition: 0.24s cubic-bezier(0.52, 0.01, 0.8, 1);

    & li {
      transform: translateX(-5px);
      transition: 0.16s cubic-bezier(0.44, 0.09, 0.46, 0.84);
      opacity: 0;
    }

    & a {
      display: block;
      font-size: 1.75em;
      font-weight: bold;
      text-decoration: none;
      color: inherit;
      transition: 0.24s ease-in-out;

      &:hover {
        text-decoration: none;
        color: var(--white);
      }
    }

    &[data-state='open'] {
      transform: translateX(0%);

      & ul {
        @for $i from 1 through 4 {
          li:nth-child(#{$i}) {
            transition-delay: 0.16s * $i;
            transform: translateX(0px);
            opacity: 1;
          }
        }
      }
    }
  }
}